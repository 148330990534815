img.Photo {
  max-width: 150px;
  margin: 20px 0;
}

.action-button {
  .accept-button,
  .deny-button {
    width: 200px;
    background-color: #d59e28;
    border-radius: 10px;
    font-size: 15px;
    color: white;
    height: 35px;
    margin-right: 15px;
    margin-top: 20px;
    cursor: pointer;
  }
  .deny-button {
    background-color: #f44336;
  }
}

.form-control{
  padding: 5px 10px;
  margin: 5px 0;
  width: 25%;
}

.form-group{
  position: relative;
  margin: 25px 0 25px 25px;
}
